<script>
import { SuawSidepanel, SuawDirectMessageList } from "@suaw/suaw-component-library";
import { SidepanelDirectMessagesByPostId, SidepanelDirectMessagesByPostIdSubscription, GetDirectMessageParentPostByUserIds, GetDisplayNameByUserId } from "../operations.gql";
import { formatDistanceToNow } from "date-fns";
import * as DiscussionApi from "../api.js";

export default {
  name: "DirectMessages",
  components: { SuawSidepanel, SuawDirectMessageList },
  data() {
    return {
      showDirectMessagePanel: null,
      postId: null,
      otherUserId: null,
      isNewDirectMessage: null,
      resultSidepanelDirectMessagesByPostId: [
        // {
        //   id: "",
        //   author_id: "",
        //   posted_at: "",
        //   body_json: "",
        //   author: {
        //     avatar_color: "",
        //     avatar_url: "",
        //     is_avatar_hidden: false,
        //     id: "",
        //     deleted_at: null,
        //     first_name: "",
        //     display_name: "",
        //     initials: ""
        //   },
        //   post_users: [
        //     {
        //       id: "",
        //       user_id: "",
        //       notice_status_value: ""
        //     }
        //   ],
        //   thread: {
        //     id: "",
        //     parent_post_id: null
        //   },
        //   posts_threads: [
        //     {
        //       id: "",
        //       responses: [
        //         {
        //           id: "",
        //           author_id: "",
        //           posted_at: "",
        //           body_json: "",
        //           author: {
        //             avatar_color: "",
        //             avatar_url: "",
        //             is_avatar_hidden: false,
        //             id: "",
        //             deleted_at: null,
        //             first_name: "",
        //             display_name: "",
        //             initials: ""
        //           },
        //           post_users: [
        //             {
        //               id: "",
        //               user_id: "",
        //               notice_status_value: ""
        //             }
        //           ]
        //         }
        //       ]
        //     }
        //   ]
        // }
      ],
      resultGetDirectMessageParentPostByUserIds: [
        // {
        //   id: ""
        // }
      ],
      resultGetDisplayNameByUserId: {
        id: "",
        display_name: ""
      }
    };
  },
  apollo: {
    resultSidepanelDirectMessagesByPostId: {
      query: SidepanelDirectMessagesByPostId,
      variables() {
        return {
          postId: this.postId
        };
      },
      skip() {
        return !this.$auth.isAuthenticated || !this.postId;
      },
      loadingKey: "loadingQueriesCount",
      result() {
        this.handleReadNewMessages();
        this.$nextTick(() => {
          this.scrollToBottom();
        });
      },
      deep: true,
      subscribeToMore: {
        document: SidepanelDirectMessagesByPostIdSubscription,
        variables() {
          return {
            postId: this.postId
          };
        },
        updateQuery(previousResult, { subscriptionData }) {
          this.resultSidepanelDirectMessagesByPostId = subscriptionData.data.resultSidepanelDirectMessagesByPostId;
          this.handleReadNewMessages();
          this.$nextTick(() => {
            this.scrollToBottom();
          });
        }
      }
    },
    resultGetDirectMessageParentPostByUserIds: {
      query: GetDirectMessageParentPostByUserIds,
      variables() {
        return {
          userId1: this.$auth.user.id,
          userId2: this.otherUserId
        };
      },
      fetchPolicy: "network-only",
      loadingKey: "loadingQueriesCount",
      skip() {
        return !this.$auth.isAuthenticated || !this.otherUserId;
      },
      result({ data }) {
        const postId = data.resultGetDirectMessageParentPostByUserIds[0]?.id;
        if (postId) {
          this.postId = postId;
        } else {
          this.isNewDirectMessage = true;
        }
      }
    },
    resultGetDisplayNameByUserId: {
      query: GetDisplayNameByUserId,
      variables() {
        return {
          userId: this.otherUserId
        };
      },
      loadingKey: "loadingQueriesCount",
      skip() {
        return !this.$auth.isAuthenticated || !this.otherUserId;
      }
    }
  },
  computed: {
    isLoading() {
      return this.loadingQueriesCount > 0;
    },
    hasDirectMessageList() {
      return !!this.resultSidepanelDirectMessagesByPostId?.length > 0;
    },
    directMessageList() {
      if (!this.hasDirectMessageList) return [];

      // Flatten the parent post and its responses into a single list
      let messages = [];

      this.resultSidepanelDirectMessagesByPostId.forEach(message => {
        messages.push(message);

        if (message.posts_threads && message.posts_threads.length) {
          message.posts_threads.forEach(thread => {
            messages.push(...thread.responses);
          });
        }
      });

      messages.sort((a, b) => new Date(a.posted_at) - new Date(b.posted_at));

      return messages.map(message => {
        const isOwner = message.author.id === this.$auth.user.id;
        const userPost = message.post_users.find(postUser => postUser.user?.id === this.$auth.user.id);
        const isUnread = userPost && (userPost.notice_status_value === "Unseen" || userPost.notice_status_value === "Unread");

        let aColor = message.author.avatar_color ? message.author.avatar_color : "blue";
        let aType = !message.author.is_avatar_hidden && message.author.avatar_url ? "picture" : aColor;
        let aAvatar = !message.author.is_avatar_hidden && aType === "picture" ? message.author.avatar_url : message.author.initials;

        return {
          id: message.id,
          text: message.body_json,
          posted: formatDistanceToNow(new Date(message.posted_at), { addSuffix: true }),
          chipContent: aAvatar,
          chipColor: aColor,
          chipText: isOwner ? "You" : message.author.display_name,
          chipInitials: message.author.initials,
          isOwner: isOwner,
          isUnread: isUnread
        };
      });
    },
    recipientUserId() {
      if (this.hasDirectMessageList) {
        const message = this.resultSidepanelDirectMessagesByPostId[0];
        const recipient = message.post_users.find(postUser => postUser.user?.id !== this.$auth.user.id);
        return recipient ? recipient.user.id : null;
      }
      if (this.isNewDirectMessage) return this.otherUserId;
      return null;
    },
    recipientDisplayName() {
      if (this.hasDirectMessageList) {
        const message = this.resultSidepanelDirectMessagesByPostId[0];
        const recipient = message.post_users.find(postUser => postUser.user?.id !== this.$auth.user.id);
        return recipient ? recipient.user.display_name : null;
      }
      if (this.isNewDirectMessage) return this.resultGetDisplayNameByUserId.display_name;
      return null;
    },
    parentPostId() {
      if (this.hasDirectMessageList) {
        const message = this.resultSidepanelDirectMessagesByPostId.find(message => message.thread && message.thread.parent_post_id === null);
        return message ? message.id : null;
      }
      return null;
    }
  },
  created() {
    this.$root.$on("open-direct-message", this.openDirectMessagePanel);
  },
  beforeDestroy() {
    this.$root.$off("open-direct-message");
  },
  methods: {
    scrollToBottom() {
      const container = this.$el.querySelector(".suaw-sidepanel__content");
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
    },
    openDirectMessagePanel(messageDetails) {
      if (messageDetails.postId) {
        this.postId = messageDetails.postId;
      }
      if (messageDetails.otherUserId) {
        this.otherUserId = messageDetails.otherUserId;
      }
      this.showDirectMessagePanel = true;
    },
    closeDirectMessagePanel() {
      this.postId = null;
      this.otherUserId = null;
      this.isNewDirectMessage = false;
      this.showDirectMessagePanel = false;
      //setting to empty array fixes persisting dm conversation if going from a non-empty to empty conversation
      this.resultSidepanelDirectMessagesByPostId = [];
    },
    async handleReadNewMessages() {
      const messagesToMarkRead = this.directMessageList.filter(message => message.isUnread);
      for (const message of messagesToMarkRead) {
        try {
          const result = await DiscussionApi.markPost(message.id, this.$auth.user.id, "Read");
          if (!result.success) {
            this.$root.$emit("universal-error-message", result.error);
          }
        } catch (error) {
          this.$root.$emit("universal-error-message", error.message);
        }
      }
    },
    async handleSubmitMessage({ title, text }) {
      const result = await DiscussionApi.mailUser(title, text, this.recipientUserId, this.parentPostId);
      if (result.success) {
        if (this.isNewDirectMessage) {
          this.postId = result.result.id;
          this.isNewDirectMessage = false;
        } else {
          await this.$apollo.queries.resultSidepanelDirectMessagesByPostId.refetch();
        }
      } else {
        this.$root.$emit("universal-error-message", result.error);
      }
    }
  }
};
</script>

<template>
  <SuawSidepanel v-if="showDirectMessagePanel" :sidepanel-title="recipientDisplayName" :is-direct-messages="true" @button-clicked="closeDirectMessagePanel">
    <SuawDirectMessageList v-if="showDirectMessagePanel" :notification-list-items="directMessageList" @submit-reply="handleSubmitMessage" />
  </SuawSidepanel>
</template>
