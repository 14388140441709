import Vue from "vue";
import VueRouter from "vue-router";

import authGuard from "../utils/auth/authGuard";
import accountFinishedCheck from "../utils/auth/accountFinishedCheck";
import provisionalLoginCheck from "../utils/auth/provisionalLoginCheck";
import seriesLookup from "../utils/auth/seriesLookup";
import eventLookup from "../utils/auth/eventLookup";
import maintenanceCheck from "../utils/auth/maintenanceCheck";
import eventOrganizerGuard from "../utils/auth/eventOrganizerGuard";
import seriesOrganizerGuard from "../utils/auth/seriesOrganizerGuard";
import chapterOrganizerGuard from "../utils/auth/chapterOrganizerGuard";
import adminGuard from "../utils/auth/adminGuard";
import homeGuard from "../utils/auth/homeGuard";
import seriesOnlyGuard from "../utils/auth/seriesOnlyGuard";
import manifestEventGuard from "../utils/auth/manifestEventGuard";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    beforeEnter: homeGuard,
    component: () => import("../features/home/Home.vue")
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: () => import("../features/home/Maintenance.vue")
  },
  //user
  {
    path: "/sign-in",
    name: "SignIn",
    component: () => import("../features/users/auth/views/SignIn.vue"),
    props: route => ({
      reason: route.params.reason,
      tab: route.query.tab
    })
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import("../features/users/auth/views/ResetPassword.vue")
  },
  {
    path: "/reset-password/:token",
    name: "ResetPasswordToken",
    component: () => import("../features/users/auth/views/ResetPassword.vue")
  },
  {
    path: "/complete-account",
    name: "CompleteAccount",
    component: () => import("../features/users/auth/views/CompleteAccount.vue")
  },
  {
    path: "/complete-account/:token",
    name: "CompleteAccountToken",
    component: () => import("../features/users/auth/views/CompleteAccount.vue")
  },
  {
    path: "/users/:userId",
    name: "UserProfile",
    component: () => import("../features/users/dashboard/views/DashboardProfileView.vue"),
    props: { isDashboard: false }
  },
  //user admin
  {
    path: "/a/user-search",
    name: "UserSearch",
    beforeEnter: adminGuard,
    component: () => import("../features/users/admin/views/UserSearch.vue")
  },
  {
    path: "/a/user/:userId",
    name: "UserView",
    beforeEnter: adminGuard,
    component: () => import("../features/users/admin/views/UserView.vue")
  },
  //dashboard
  {
    path: "/dashboard/",
    name: "DashboardEvents",
    beforeEnter: authGuard,
    component: () => import("../features/users/dashboard/views/DashboardEvents.vue")
  },
  {
    path: "/dashboard/profile",
    name: "DashboardProfileView",
    beforeEnter: authGuard,
    component: () => import("../features/users/dashboard/views/DashboardProfileView.vue"),
    props: { isDashboard: true }
  },
  {
    path: "/dashboard/profile/edit",
    name: "DashboardProfileEdit",
    beforeEnter: authGuard,
    component: () => import("../features/users/dashboard/views/DashboardProfileEdit.vue")
  },
  {
    path: "/dashboard/mail",
    name: "DashboardMail",
    beforeEnter: authGuard,
    component: () => import("../features/users/dashboard/views/DashboardMail.vue")
  },
  {
    path: "/dashboard/settings",
    name: "DashboardSettingsView",
    beforeEnter: authGuard,
    component: () => import("../features/users/dashboard/views/DashboardSettingsView.vue")
  },
  {
    path: "/dashboard/settings/view/:token",
    name: "DashboardSettingsViewToken",
    beforeEnter: authGuard,
    component: () => import("../features/users/dashboard/views/DashboardSettingsView.vue")
  },
  {
    path: "/dashboard/settings/edit",
    name: "DashboardSettingsEdit",
    beforeEnter: authGuard,
    component: () => import("../features/users/dashboard/views/DashboardSettingsEdit.vue")
  },
  {
    path: "/series/:seriesId/event-edit/:eventId",
    name: "EventEdit",
    beforeEnter: eventOrganizerGuard,
    component: () => import("../features/events/edit/views/EventEdit.vue")
  },
  {
    path: "/online-events",
    name: "OnlineEventDiscovery",
    component: () => import("../features/events/discovery/views/OnlineEventDiscovery.vue"),
    props: route => ({
      selectedDate: route.query.selectedDate || null
    })
  },
  //legacy event routes that are still needed
  {
    path: "/events/:slug-writing-group/:event_id",
    name: "event_detail",
    beforeEnter: eventLookup
  },
  {
    path: "/events/:slug/:event_id",
    name: "event_detail_non_seo",
    beforeEnter: eventLookup
  },
  //series
  {
    path: "/chapter/:chapterSlug/series/:seriesSlug/event/:eventId",
    name: "Series",
    component: () => import("../features/series/details/views/Series.vue"),
    beforeEnter: manifestEventGuard
  },
  {
    path: "/chapter/:chapterSlug/series/:seriesId",
    name: "SeriesOnly",
    beforeEnter: seriesOnlyGuard
  },
  {
    path: "/chapter/:chapterId/series-create/:seriesId?",
    name: "SeriesCreate",
    beforeEnter: chapterOrganizerGuard,
    component: () => import("../features/series/create/views/SeriesCreate.vue")
  },
  {
    path: "/chapter/:chapterId/series-edit/:seriesId",
    name: "SeriesEdit",
    beforeEnter: seriesOrganizerGuard,
    component: () => import("../features/series/create/views/SeriesEdit.vue")
  },
  //legacy series routes that are still needed
  {
    path: "/series-lookup/:event_template_id",
    name: "legacy_series_lookup",
    beforeEnter: seriesLookup
  },
  {
    path: "/series/:slug/:event_template_id",
    name: "legacy_series",
    beforeEnter: seriesLookup
  },
  //series admin
  {
    path: "/a/series/:seriesId",
    name: "SeriesView",
    component: () => import("../features/series/admin/views/SeriesView.vue")
  },
  //chapter
  {
    path: "/chapter/:chapterId/:chapterSlug",
    name: "Chapter",
    component: () => import("../features/chapters/details/views/Chapter.vue")
  },
  {
    path: "/a/chapter-create",
    name: "ChapterCreate",
    beforeEnter: adminGuard,
    component: () => import("../features/chapters/create/views/ChapterCreate.vue")
  },
  {
    path: "/chapters",
    name: "ChapterDiscovery",
    component: () => import("../features/chapters/discovery/views/ChapterDiscovery.vue")
  },
  //chapter admin
  {
    path: "/a/chapter-search",
    name: "ChapterSearch",
    beforeEnter: adminGuard,
    component: () => import("../features/chapters/admin/views/ChapterSearch.vue")
  },
  {
    path: "/a/chapter/:chapterId",
    name: "ChapterView",
    beforeEnter: adminGuard,
    component: () => import("../features/chapters/admin/views/ChapterView.vue")
  },
  //Prototyping/testing
  // {
  //   path: "/apiTest",
  //   name: "ApiTest",
  //   beforeEnter: adminGuard,
  //   component: () => import("../features/prototype/ApiTest.vue")
  // },
  //resources
  {
    path: "/resources",
    name: "Resources-Index",
    component: () => import("../features/resources/views/ResourcesIndex.vue")
  },
  {
    path: "/about",
    name: "Resources-About-Legacy",
    component: () => import("../features/resources/views/About.vue")
  },
  {
    path: "/resources/about",
    name: "Resources-About",
    component: () => import("../features/resources/views/About.vue")
  },
  {
    path: "/resources/method",
    name: "Resources-Method",
    component: () => import("../features/resources/views/Method.vue")
  },
  {
    path: "/resources/impact",
    name: "Resources-Impact",
    component: () => import("../features/resources/views/Impact.vue")
  },
  {
    path: "/resources/get-involved",
    name: "Resources-GetInvolved",
    component: () => import("../features/resources/views/GetInvolved.vue")
  },
  {
    path: "/host",
    name: "Resources-BecomeAHost-Legacy",
    component: () => import("../features/resources/views/BecomeAnOrganizer.vue")
  },
  {
    path: "/organizer",
    name: "Resources-BecomeAnOrganizer-Legacy",
    component: () => import("../features/resources/views/BecomeAnOrganizer.vue")
  },
  {
    path: "/resources/organizer",
    name: "Resources-BecomeAnOrganizer",
    component: () => import("../features/resources/views/BecomeAnOrganizer.vue")
  },
  {
    path: "/downloads",
    name: "Resources-Downloads-Legacy",
    component: () => import("../features/resources/views/Downloads.vue")
  },
  {
    path: "/resources/downloads",
    name: "Resources-Downloads",
    component: () => import("../features/resources/views/Downloads.vue")
  },
  {
    path: "/code-of-conduct",
    name: "Resources-CodeOfConduct-Legacy",
    component: () => import("../features/resources/views/CodeOfConduct.vue")
  },
  {
    path: "/resources/code-of-conduct",
    name: "Resources-CodeOfConduct",
    component: () => import("../features/resources/views/CodeOfConduct.vue")
  },
  {
    path: "/faq",
    name: "Resources-Faq-Legacy",
    component: () => import("../features/resources/views/Faq.vue")
  },
  {
    path: "/resources/faq",
    name: "Resources-Faq",
    component: () => import("../features/resources/views/Faq.vue")
  },
  {
    path: "/contact",
    name: "Resources-Contact-Legacy",
    component: () => import("../features/resources/views/Contact.vue")
  },
  {
    path: "/resources/contact",
    name: "Resources-Contact",
    component: () => import("../features/resources/views/Contact.vue")
  },
  {
    path: "/resources/our-organization",
    name: "Resources-OurOrganization",
    component: () => import("../features/resources/views/OurOrganization.vue")
  },
  {
    path: "/organizer-faq",
    name: "Resources-OrganizerFaq-Legacy",
    component: () => import("../features/resources/views/OrganizerFaq.vue")
  },
  {
    path: "/resources/organizer-faq",
    name: "Resources-OrganizerFaq",
    component: () => import("../features/resources/views/OrganizerFaq.vue")
  },
  {
    path: "/organizer-handbook",
    name: "Resources-OrganizerHandbook-Legacy",
    component: () => import("../features/resources/views/OrganizerHandbook.vue")
  },
  {
    path: "/resources/organizer-handbook",
    name: "Resources-OrganizerHandbook",
    component: () => import("../features/resources/views/OrganizerHandbook.vue")
  },
  {
    path: "/privacy-policy",
    name: "Resources-PrivacyPolicy-Legacy",
    component: () => import("../features/resources/views/PrivacyPolicy.vue")
  },
  {
    path: "/resources/privacy-policy",
    name: "Resources-PrivacyPolicy",
    component: () => import("../features/resources/views/PrivacyPolicy.vue")
  },
  {
    path: "/tos",
    name: "Resources-TermsOfService-Legacy",
    component: () => import("../features/resources/views/TermsOfService.vue")
  },
  {
    path: "/resources/tos",
    name: "Resources-TermsOfService",
    component: () => import("../features/resources/views/TermsOfService.vue")
  },
  {
    path: "/legal",
    name: "Resources-Legal-Legacy",
    component: () => import("../features/resources/views/Legal.vue")
  },
  {
    path: "/resources/legal",
    name: "Resources-Legal",
    component: () => import("../features/resources/views/Legal.vue")
  },
  // articles (and legacy article routes)
  {
    path: "/articles",
    name: "ArticleDiscovery",
    component: () => import("../features/articles/views/ArticleDiscovery.vue")
  },
  {
    path: "/beginning-tips-for-writers-the-basics",
    name: "article-beginning-tips-for-writers-the-basics",
    component: () => import("../features/articles/views/20160213_BeginningTipsForWritersTheBasics.vue")
  },
  {
    path: "/articles/beginning-tips-for-writers-the-basics",
    name: "articles-beginning-tips-for-writers-the-basics",
    component: () => import("../features/articles/views/20160213_BeginningTipsForWritersTheBasics.vue")
  },
  {
    path: "/conquer-procrastination-and-get-writing",
    name: "article-conquer-procrastination-and-get-writing",
    component: () => import("../features/articles/views/20160403_ConquerProcrastinationAndGetWriting.vue")
  },
  {
    path: "/articles/conquer-procrastination-and-get-writing",
    name: "articles-conquer-procrastination-and-get-writing",
    component: () => import("../features/articles/views/20160403_ConquerProcrastinationAndGetWriting.vue")
  },
  {
    path: "/perfectionism-distracts-you-from-writing-perfectly",
    name: "article-perfectionism-distracts-you-from-writing-perfectly",
    component: () => import("../features/articles/views/20160405_PerfectionismDistractsYouFromWritingPerfectly.vue")
  },
  {
    path: "/articles/perfectionism-distracts-you-from-writing-perfectly",
    name: "articles-perfectionism-distracts-you-from-writing-perfectly",
    component: () => import("../features/articles/views/20160405_PerfectionismDistractsYouFromWritingPerfectly.vue")
  },
  {
    path: "/beginning-tips-for-writers-working-styles-and-wip",
    name: "article-beginning-tips-for-writers-working-styles-and-wip",
    component: () => import("../features/articles/views/20160416_BeginningTipsForWritersWorkingStylesAndWip.vue")
  },
  {
    path: "/articles/beginning-tips-for-writers-working-styles-and-wip",
    name: "articles-beginning-tips-for-writers-working-styles-and-wip",
    component: () => import("../features/articles/views/20160416_BeginningTipsForWritersWorkingStylesAndWip.vue")
  },
  {
    path: "/after-the-first-draft-pro-tips-for-editing-your-work",
    name: "article-after-the-first-draft-pro-tips-for-editing-your-work",
    component: () => import("../features/articles/views/20160518_AfterTheFirstDraftProTipsForEditingYourWork.vue")
  },
  {
    path: "/articles/after-the-first-draft-pro-tips-for-editing-your-work",
    name: "articles-after-the-first-draft-pro-tips-for-editing-your-work",
    component: () => import("../features/articles/views/20160518_AfterTheFirstDraftProTipsForEditingYourWork.vue")
  },
  {
    path: "/five-geeky-hobbies-for-writers-that-arent-reading",
    name: "article-five-geeky-hobbies-for-writers-that-arent-reading",
    component: () => import("../features/articles/views/20170110_FiveGeekyHobbiesForWritersThatArentReading.vue")
  },
  {
    path: "/articles/five-geeky-hobbies-for-writers-that-arent-reading",
    name: "articles-five-geeky-hobbies-for-writers-that-arent-reading",
    component: () => import("../features/articles/views/20170110_FiveGeekyHobbiesForWritersThatArentReading.vue")
  },
  {
    path: "/attending-the-muse-or-your-writing-discipline",
    name: "article-attending-the-muse-or-your-writing-discipline",
    component: () => import("../features/articles/views/20170228_AttendingTheMuseOrYourWritingDiscipline.vue")
  },
  {
    path: "/articles/attending-the-muse-or-your-writing-discipline",
    name: "articles-attending-the-muse-or-your-writing-discipline",
    component: () => import("../features/articles/views/20170228_AttendingTheMuseOrYourWritingDiscipline.vue")
  },
  {
    path: "/its-okay-to-be-edited-really",
    name: "article-its-okay-to-be-edited-really",
    component: () => import("../features/articles/views/20170901_ItsOkayToBeEditedReally.vue")
  },
  {
    path: "/articles/its-okay-to-be-edited-really",
    name: "articls-its-okay-to-be-edited-really",
    component: () => import("../features/articles/views/20170901_ItsOkayToBeEditedReally.vue")
  },
  {
    path: "/5-simple-habits-to-boost-your-writing",
    name: "article-5-simple-habits-to-boost-your-writing",
    component: () => import("../features/articles/views/20180204_FiveSimpleHabitsToBoostYourWriting.vue")
  },
  {
    path: "/articles/5-simple-habits-to-boost-your-writing",
    name: "articles-5-simple-habits-to-boost-your-writing",
    component: () => import("../features/articles/views/20180204_FiveSimpleHabitsToBoostYourWriting.vue")
  },
  {
    path: "/4-easy-steps-to-a-writing-plan",
    name: "article-4-easy-steps-to-a-writing-plan",
    component: () => import("../features/articles/views/20180412_FourEasyStepsToAWritingPlan.vue")
  },
  {
    path: "/articles/4-easy-steps-to-a-writing-plan",
    name: "articles-4-easy-steps-to-a-writing-plan",
    component: () => import("../features/articles/views/20180412_FourEasyStepsToAWritingPlan.vue")
  },
  {
    path: "/shut-up-and-writes-nanowrimo-survival-guide",
    name: "article-shut-up-and-writes-nanowrimo-survival-guide",
    component: () => import("../features/articles/views/20191101_ShutUpAndWritesNanowrimoSurvivalGuide.vue")
  },
  {
    path: "/articles/shut-up-and-writes-nanowrimo-survival-guide",
    name: "articles-shut-up-and-writes-nanowrimo-survival-guide",
    component: () => import("../features/articles/views/20191101_ShutUpAndWritesNanowrimoSurvivalGuide.vue")
  },
  {
    path: "/five-ways-to-silence-your-inner-critic-while-drafting",
    name: "article-five-ways-to-silence-your-inner-critic-while-drafting",
    component: () => import("../features/articles/views/20191201_FiveWaysToSilenceYourInnerCriticWhileDrafting.vue")
  },
  {
    path: "/articles/five-ways-to-silence-your-inner-critic-while-drafting",
    name: "articles-five-ways-to-silence-your-inner-critic-while-drafting",
    component: () => import("../features/articles/views/20191201_FiveWaysToSilenceYourInnerCriticWhileDrafting.vue")
  },
  {
    path: "/creating-your-writing-environment",
    name: "article-creating-your-writing-environment",
    component: () => import("../features/articles/views/20200129_CreatingYourWritingEnvironment.vue")
  },
  {
    path: "/articles/creating-your-writing-environment",
    name: "articles-creating-your-writing-environment",
    component: () => import("../features/articles/views/20200129_CreatingYourWritingEnvironment.vue")
  },
  {
    path: "/the-writers-guide-to-editors",
    name: "article-the-writers-guide-to-editors",
    component: () => import("../features/articles/views/20200221_TheWritersGuideToEditors.vue")
  },
  {
    path: "/articles/the-writers-guide-to-editors",
    name: "articles-the-writers-guide-to-editors",
    component: () => import("../features/articles/views/20200221_TheWritersGuideToEditors.vue")
  },
  {
    path: "/10-best-practices-for-writing-from-home",
    name: "article-10-best-practices-for-writing-from-home",
    component: () => import("../features/articles/views/20200320_TenBestPracticesForWritingFromHome.vue")
  },
  {
    path: "/articles/10-best-practices-for-writing-from-home",
    name: "articles-10-best-practices-for-writing-from-home",
    component: () => import("../features/articles/views/20200320_TenBestPracticesForWritingFromHome.vue")
  },
  {
    path: "/the-writers-guide-to-copyright",
    name: "article-the-writers-guide-to-copyright",
    component: () => import("../features/articles/views/20200406_TheWritersGuideToCopyright.vue")
  },
  {
    path: "/articles/the-writers-guide-to-copyright",
    name: "articles-the-writers-guide-to-copyright",
    component: () => import("../features/articles/views/20200406_TheWritersGuideToCopyright.vue")
  },
  {
    path: "/seven-strategies-to-get-you-writing-again",
    name: "article-seven-strategies-to-get-you-writing-again",
    component: () => import("../features/articles/views/20200526_SevenStrategiesToGetYouWritingAgain.vue")
  },
  {
    path: "/articles/seven-strategies-to-get-you-writing-again",
    name: "articles-seven-strategies-to-get-you-writing-again",
    component: () => import("../features/articles/views/20200526_SevenStrategiesToGetYouWritingAgain.vue")
  },
  {
    path: "/four-essential-steps-to-developing-your-writing-practice",
    name: "article-four-essential-steps-to-developing-your-writing-practice",
    component: () => import("../features/articles/views/20200527_FourEssentialStepsToDevelopingYourWritingPractice.vue")
  },
  {
    path: "/articles/four-essential-steps-to-developing-your-writing-practice",
    name: "articles-four-essential-steps-to-developing-your-writing-practice",
    component: () => import("../features/articles/views/20200527_FourEssentialStepsToDevelopingYourWritingPractice.vue")
  },
  {
    path: "/how-to-get-feedback-on-your-writing",
    name: "article-how-to-get-feedback-on-your-writing",
    component: () => import("../features/articles/views/20200706_HowToGetFeedbackOnYourWriting.vue")
  },
  {
    path: "/articles/how-to-get-feedback-on-your-writing",
    name: "articles-how-to-get-feedback-on-your-writing",
    component: () => import("../features/articles/views/20200706_HowToGetFeedbackOnYourWriting.vue")
  },
  {
    path: "/tricks-for-writing-under-pressure",
    name: "article-tricks-for-writing-under-pressure",
    component: () => import("../features/articles/views/20200715_TricksForWritingUnderPressure.vue")
  },
  {
    path: "/articles/tricks-for-writing-under-pressure",
    name: "articles-tricks-for-writing-under-pressure",
    component: () => import("../features/articles/views/20200715_TricksForWritingUnderPressure.vue")
  },
  {
    path: "/how-to-get-a-book-cover-youre-proud-of",
    name: "article-how-to-get-a-book-cover-youre-proud-of",
    component: () => import("../features/articles/views/20200814_HowToGetABookCoverYoureProudOf.vue")
  },
  {
    path: "/articles/how-to-get-a-book-cover-youre-proud-of",
    name: "articles-how-to-get-a-book-cover-youre-proud-of",
    component: () => import("../features/articles/views/20200814_HowToGetABookCoverYoureProudOf.vue")
  },
  {
    path: "/finding-and-working-with-a-beta-reader",
    name: "article-finding-and-working-with-a-beta-reader",
    component: () => import("../features/articles/views/20200818_FindingAndWorkingWithABetaReader.vue")
  },
  {
    path: "/articles/finding-and-working-with-a-beta-reader",
    name: "articles-finding-and-working-with-a-beta-reader",
    component: () => import("../features/articles/views/20200818_FindingAndWorkingWithABetaReader.vue")
  },
  {
    path: "/how-i-completed-my-first-novel-with-the-help-of-shut-up-and-write",
    name: "article-how-i-completed-my-first-novel-with-the-help-of-shut-up-and-write",
    component: () => import("../features/articles/views/20200821_HowICompletedMyFirstNovelWithTheHelpOfShutUpAndWrite.vue")
  },
  {
    path: "/articles/how-i-completed-my-first-novel-with-the-help-of-shut-up-and-write",
    name: "articles-how-i-completed-my-first-novel-with-the-help-of-shut-up-and-write",
    component: () => import("../features/articles/views/20200821_HowICompletedMyFirstNovelWithTheHelpOfShutUpAndWrite.vue")
  },
  {
    path: "/how-to-edit-the-first-draft-of-your-novel",
    name: "article-how-to-edit-the-first-draft-of-your-novel",
    component: () => import("../features/articles/views/20200921_HowToEditTheFirstDraftOfYourNovel.vue")
  },
  {
    path: "/articles/how-to-edit-the-first-draft-of-your-novel",
    name: "articles-how-to-edit-the-first-draft-of-your-novel",
    component: () => import("../features/articles/views/20200921_HowToEditTheFirstDraftOfYourNovel.vue")
  },
  {
    path: "/between-editing-and-submission",
    name: "article-between-editing-and-submission",
    component: () => import("../features/articles/views/20200929_BetweenEditingAndSubmission.vue")
  },
  {
    path: "/articles/between-editing-and-submission",
    name: "articles-between-editing-and-submission",
    component: () => import("../features/articles/views/20200929_BetweenEditingAndSubmission.vue")
  },
  {
    path: "/how-to-find-a-literary-agent",
    name: "article-how-to-find-a-literary-agent",
    component: () => import("../features/articles/views/20201026_HowToFindALiteraryAgent.vue")
  },
  {
    path: "/articles/how-to-find-a-literary-agent",
    name: "articles-how-to-find-a-literary-agent",
    component: () => import("../features/articles/views/20201026_HowToFindALiteraryAgent.vue")
  },
  {
    path: "/make-writing-a-habit-not-a-choice",
    name: "article-make-writing-a-habit-not-a-choice",
    component: () => import("../features/articles/views/20201118_MakeWritingAHabitNotAChoice.vue")
  },
  {
    path: "/articles/make-writing-a-habit-not-a-choice",
    name: "articles-make-writing-a-habit-not-a-choice",
    component: () => import("../features/articles/views/20201118_MakeWritingAHabitNotAChoice.vue")
  },
  {
    path: "/staying-creative-while-the-world-is-burning",
    name: "article-staying-creative-while-the-world-is-burning",
    component: () => import("../features/articles/views/20201118_StayingCreativeWhileTheWorldIsBurning.vue")
  },
  {
    path: "/articles/staying-creative-while-the-world-is-burning",
    name: "articles-staying-creative-while-the-world-is-burning",
    component: () => import("../features/articles/views/20201118_StayingCreativeWhileTheWorldIsBurning.vue")
  },
  {
    path: "/how-to-self-edit-in-six-painless-steps",
    name: "article-how-to-self-edit-in-six-painless-steps",
    component: () => import("../features/articles/views/20201120_HowToSelfEditInSixPainlessSteps.vue")
  },
  {
    path: "/articles/how-to-self-edit-in-six-painless-steps",
    name: "articles-how-to-self-edit-in-six-painless-steps",
    component: () => import("../features/articles/views/20201120_HowToSelfEditInSixPainlessSteps.vue")
  },
  {
    path: "/should-you-self-publish-or-traditionally-publish",
    name: "article-should-you-self-publish-or-traditionally-publish",
    component: () => import("../features/articles/views/20201208_ShouldYouSelfPublishOrTraditionallyPublish.vue")
  },
  {
    path: "/articles/should-you-self-publish-or-traditionally-publish",
    name: "articles-should-you-self-publish-or-traditionally-publish",
    component: () => import("../features/articles/views/20201208_ShouldYouSelfPublishOrTraditionallyPublish.vue")
  },
  {
    path: "/how-to-work-with-an-illustrator",
    name: "article-how-to-work-with-an-illustrator",
    component: () => import("../features/articles/views/20201216_HowToWorkWithAnIllustrator.vue")
  },
  {
    path: "/articles/how-to-work-with-an-illustrator",
    name: "articles-how-to-work-with-an-illustrator",
    component: () => import("../features/articles/views/20201216_HowToWorkWithAnIllustrator.vue")
  },
  {
    path: "/how-to-keep-your-writing-on-track",
    name: "article-how-to-keep-your-writing-on-track",
    component: () => import("../features/articles/views/20210113_HowToKeepYourWritingOnTrack.vue")
  },
  {
    path: "/articles/how-to-keep-your-writing-on-track",
    name: "articles-how-to-keep-your-writing-on-track",
    component: () => import("../features/articles/views/20210113_HowToKeepYourWritingOnTrack.vue")
  },
  {
    path: "/the-elements-of-storytelling-for-novelists",
    name: "article-the-elements-of-storytelling-for-novelists",
    component: () => import("../features/articles/views/20210119_TheElementsOfStorytellingForNovelists.vue")
  },
  {
    path: "/articles/the-elements-of-storytelling-for-novelists",
    name: "articles-the-elements-of-storytelling-for-novelists",
    component: () => import("../features/articles/views/20210119_TheElementsOfStorytellingForNovelists.vue")
  },
  {
    path: "/five-steps-to-improve-pacing-in-your-story",
    name: "article-five-steps-to-improve-pacing-in-your-story",
    component: () => import("../features/articles/views/20210211_FiveStepsToImprovePacingInYourStory.vue")
  },
  {
    path: "/articles/five-steps-to-improve-pacing-in-your-story",
    name: "articles-five-steps-to-improve-pacing-in-your-story",
    component: () => import("../features/articles/views/20210211_FiveStepsToImprovePacingInYourStory.vue")
  },
  {
    path: "/submissions-the-harsh-reality-and-how-to-improve-your-odds",
    name: "article-submissions-the-harsh-reality-and-how-to-improve-your-odds",
    component: () => import("../features/articles/views/20210603_SubmissionsTheHarshRealityAndHowToImproveYourOdds.vue")
  },
  {
    path: "/articles/submissions-the-harsh-reality-and-how-to-improve-your-odds",
    name: "articles-submissions-the-harsh-reality-and-how-to-improve-your-odds",
    component: () => import("../features/articles/views/20210603_SubmissionsTheHarshRealityAndHowToImproveYourOdds.vue")
  },
  {
    path: "/five-ways-to-create-new-settings-in-your-writing",
    name: "article-five-ways-to-create-new-settings-in-your-writing",
    component: () => import("../features/articles/views/20210628_FiveWaysToCreateNewSettingsInYourWriting.vue")
  },
  {
    path: "/articles/five-ways-to-create-new-settings-in-your-writing",
    name: "articles-five-ways-to-create-new-settings-in-your-writing",
    component: () => import("../features/articles/views/20210628_FiveWaysToCreateNewSettingsInYourWriting.vue")
  },
  {
    path: "/five-traits-of-an-unforgettable-scene",
    name: "article-five-traits-of-an-unforgettable-scene",
    component: () => import("../features/articles/views/20210717_FiveTraitsOfAnUnforgettableScene.vue")
  },
  {
    path: "/articles/five-traits-of-an-unforgettable-scene",
    name: "articles-five-traits-of-an-unforgettable-scene",
    component: () => import("../features/articles/views/20210717_FiveTraitsOfAnUnforgettableScene.vue")
  },
  {
    path: "/when-should-i-call-myself-a-writer",
    name: "article-when-should-i-call-myself-a-writer",
    component: () => import("../features/articles/views/20230217_WhenShouldICallMyselfAWriter.vue")
  },
  {
    path: "/articles/when-should-i-call-myself-a-writer",
    name: "articles-when-should-i-call-myself-a-writer",
    component: () => import("../features/articles/views/20230217_WhenShouldICallMyselfAWriter.vue")
  },
  {
    path: "/what-should-i-write-about-five-ways-to-find-new-writing-ideas",
    name: "article-what-should-i-write-about-five-ways-to-find-new-writing-ideas",
    component: () => import("../features/articles/views/20230127_WhatShouldIWriteAboutFiveWaysToFindNewWritingIdeas.vue")
  },
  {
    path: "/articles/what-should-i-write-about-five-ways-to-find-new-writing-ideas",
    name: "articles-what-should-i-write-about-five-ways-to-find-new-writing-ideas",
    component: () => import("../features/articles/views/20230127_WhatShouldIWriteAboutFiveWaysToFindNewWritingIdeas.vue")
  },
  {
    path: "/i-wrote-1000-words-for-30-days-and-here-is-what-happened",
    name: "article-i-wrote-1000-words-for-30-days-and-here-is-what-happened",
    component: () => import("../features/articles/views/20230321_IWrote1000WordsFor30DaysAndHereIsWhatHappened.vue")
  },
  {
    path: "/articles/i-wrote-1000-words-for-30-days-and-here-is-what-happened",
    name: "articles-i-wrote-1000-words-for-30-days-and-here-is-what-happened",
    component: () => import("../features/articles/views/20230321_IWrote1000WordsFor30DaysAndHereIsWhatHappened.vue")
  },
  {
    path: "/how-to-make-friends-with-chat-gpt",
    name: "article-how-to-make-friends-with-chat-gpt",
    component: () => import("../features/articles/views/20230501_HowToMakeFriendsWithChatGpt.vue")
  },
  {
    path: "/articles/how-to-make-friends-with-chat-gpt",
    name: "articles-how-to-make-friends-with-chat-gpt",
    component: () => import("../features/articles/views/20230501_HowToMakeFriendsWithChatGpt.vue")
  },
  {
    path: "*",
    name: "404",
    component: () => import("../features/common/views/NotFoundPage.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  }
});

router.beforeEach(accountFinishedCheck);
router.beforeEach(provisionalLoginCheck);
//router.beforeEach(maintenanceCheck); //comment this line out (and deploy) when we aren't in maintenance mode

export default router;
